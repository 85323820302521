<template>
  <ul class="base-list" v-if="type === 'ul'">
    <slot/>
  </ul>
  <ul class="base-list" v-else-if="type === 'ol'">
    <slot/>
  </ul>
</template>

<script>
export default {
  name: 'BaseList',
  props: {
    type: {
      type: String,
      default: 'ul',
      validator(val) {
        return ['ul', 'ol'].includes(val)
      },
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.base-list {
  margin: $row-height * 2 0;
  padding-left: $row-height * 1.2;
}
</style>

<template>
  <div class="base-video" :class="{'base-video_paused': videoPaused}">
    <video
      :src="src"
      :muted="videoMuted"
      loop
      ref="video"
      @click="play"
    />
    <div class="base-video__controls">
      <div class="base-video__controls-left">
        <div class="base-video__play base-video__icon" @click="play">
          <play-icon v-if="videoPaused"/>
          <pause-icon v-else/>
        </div>
        <div class="base-video__time">{{ time }}</div>
      </div>
      <div class="base-video__controls-right">
        <div class="base-video__fullscreen base-video__icon" @click="fullScreen">
          <fullscreen-icon/>
        </div>
        <div class="base-video__mute base-video__icon" @click="mute">
          <volume-off-icon v-if="videoMuted"/>
          <volume-high-icon v-else/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue'
import {
  VolumeHigh,
  VolumeOff,
  Fullscreen,
  Play,
  Pause,
} from 'mdue'

export default {
  name: 'BaseVideo',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  setup() {
    const video = ref({})
    const videoPaused = ref(true)
    const videoMuted = ref(true)
    const time = ref('00:00')

    const getTime = seconds => {
      const date = new Date(0)
      date.setSeconds(seconds)
      return date.toISOString().substr(14, 5)
    }

    onMounted(() => {
      let lastTime = 0
      if (video.value instanceof HTMLMediaElement) {
        video.value.addEventListener('timeupdate', (event) => {
          const currentTime = Math.floor(event.target.currentTime)
          if (currentTime !== lastTime) {
            time.value = getTime(event.target.currentTime)
            lastTime = currentTime
          }
        })
      }
    })

    const play = () => {
      if (video.value instanceof HTMLMediaElement) {
        if (video.value.paused) {
          video.value.play()
          videoPaused.value = false
        } else {
          video.value.pause()
          videoPaused.value = true
        }
      }
    }

    const fullScreen = () => {
      if (video.value instanceof HTMLMediaElement) {
        if (video.value.requestFullscreen) video.value.requestFullscreen()
        else if (video.value.webkitRequestFullscreen) video.value.webkitRequestFullscreen()
        else if (video.value.msRequestFullScreen) video.value.msRequestFullScreen()
      }
    }

    const mute = () => {
      if (video.value instanceof HTMLMediaElement) {
        if (video.value.muted) {
          video.value.muted = false
          videoMuted.value = false
        } else {
          video.value.muted = true
          videoMuted.value = true
        }
      }
    }

    return {
      video,
      videoPaused,
      videoMuted,
      play,
      fullScreen,
      mute,
      time,
    }
  },
  components: {
    VolumeHighIcon: VolumeHigh,
    VolumeOffIcon: VolumeOff,
    FullscreenIcon: Fullscreen,
    PlayIcon: Play,
    PauseIcon: Pause,
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.base-video {
  position: relative;

  video {
    display: block;
    max-width: 100%;
    width: calc(100% - 1px);
    height: auto;
  }

  &__controls {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    padding: 0 20px;
    background-color: rgba(0, 0, 0, .5);
    opacity: .2;
    transition: .5s;

    &-left, &-right {
      display: flex;
      align-items: center;
    }

    &:hover {
      opacity: 1;
    }
  }

  &_paused {
    .base-video__controls {
      opacity: 1;
    }
  }

  &__icon {
    margin-right: 12px;
    font-size: 24px;
    cursor: pointer;
    transition: .5s;

    &:hover {
      color: $color-pink;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>

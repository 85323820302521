<template>
  <section :class="prefix">
    <div class="container">
      <div class="row">
        <div :class="`${prefix}__info col-md-6`">
          <header :class="`${prefix}__header`" ref="header">
            <base-title :class="`${prefix}__title`">{{ $t(`${prefix}.title`) }}</base-title>
            <div :class="`${prefix}__description`">{{ $t(`${prefix}.description`) }}</div>
          </header>
        </div>
        <div :class="`${prefix}__cube col-md-6`">
          <the-cube/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseTitle from '../components/Base/BaseTitle'
import TheCube from '../components/TheCube'
import {onMounted, ref} from 'vue'
import {gsap} from '../myGsap'

export default {
  name: 'Technology',
  components: {TheCube, BaseTitle},
  setup() {
    const header = ref({})

    onMounted(() => {
      if (header.value instanceof HTMLElement) {
        setTimeout(() => {
          gsap.to(header.value, {
            opacity: 1,
            scrollTrigger: {
              trigger: header.value,
              start: 'top+=100px center',
            },
            duration: 0.9,
            ease: 'none',
          })
        }, 1000)
      }
    })

    return {
      prefix: 'technology',
      header,
    }
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.technology {
  position: relative;

  &__header {
    position: relative;
    opacity: 0;
  }

  &__title {
    margin-top: $row-height * 2.5;
    margin-bottom: $row-height;
  }

  &__description {
    font-size: 28px;
    line-height: 33px;
  }
}
</style>

import {ref} from 'vue'

export function useModal() {
  const openedModal = ref(false)
  const openModal = () => {
    openedModal.value = true
  }
  const closeModal = () => {
    openedModal.value = false
  }

  return {
    openedModal,
    openModal,
    closeModal,
  }
}

<template>
  <section class="base-content">
    <header class="base-content__header" v-if="$slots.title">
      <h2 class="base-content__title">
        <slot name="title"/>
      </h2>
    </header>
    <slot/>
  </section>
</template>

<script>
export default {
  name: 'BaseContent',
}
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.base-content {
  margin-bottom: $row-height * 3;
  font-size: 18px;
  line-height: 26px;

  &:last-child {
    margin-bottom: 0;
  }

  &__header {
    margin-bottom: $row-height;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
  }

  ol {
    padding-left: $row-height;
  }

  .base-blockquote {
    margin-top: $row-height;
    border-color: black;
  }

  .mb-2 {
    margin-bottom: $row-height * 2;
  }

  .mt-2 {
    margin-top: $row-height * 2;
  }
}
</style>

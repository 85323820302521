<template>
  <div :class="prefix">
    <swiper :class="`${prefix}__slider`" :effect="'flip'" :grabCursor="true" @swiper="onSwiper">
      <swiper-slide>
        <div :class="`${prefix}__slide`" @click="nextSlide">
          <div :class="`${prefix}__open`">
            <span>{{ $t(`${prefix}.open`) }}</span>
            <img :src="imageOpen" alt="Open">
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div :class="`${prefix}__slide ${prefix}__slide-detail`">
          <div :class="`${prefix}__detail`">
            <div :class="`${prefix}__detail-info`">
              <div :class="`${prefix}__detail-table`">
                <div :class="`${prefix}__detail-tr`" v-for="i in 4" :key="i">
                  <div :class="`${prefix}__detail-td`">
                    {{ $t(`${prefix}.detail-label-${i}`) }}
                    <span v-if="i === 4" :class="`${prefix}__detail-in`"/>
                  </div>
                  <div :class="`${prefix}__detail-td`">{{ $t(`${prefix}.detail-value-${i}`) }}</div>
                </div>
              </div>
              <a :class="`${prefix}__detail-button`" href="https://erachain.org/" target="_blank">
                <span>{{ $t(`${prefix}.site`) }}</span>
                <svg width="30" height="8" viewBox="0 0 30 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M29.3536 4.35355C29.5488 4.15829 29.5488 3.84171 29.3536 3.64645L26.1716 0.464466C25.9763 0.269204 25.6597 0.269204 25.4645 0.464466C25.2692 0.659728 25.2692 0.976311 25.4645 1.17157L28.2929 4L25.4645 6.82843C25.2692 7.02369 25.2692 7.34027 25.4645 7.53553C25.6597 7.7308 25.9763 7.7308 26.1716 7.53553L29.3536 4.35355ZM0 4.5H29V3.5H0V4.5Z"
                    fill="#190D71"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import {ref} from 'vue'
import {Swiper, SwiperSlide} from 'swiper/vue'
import SwiperCore, {EffectFlip} from 'swiper/core'

SwiperCore.use([EffectFlip])

export default {
  name: 'TheBlockchainData',
  setup() {
    const imageOpen = require('../assets/images/open.svg')
    const swiper = ref(null)
    const onSwiper = s => {
      swiper.value = s
    }
    const nextSlide = () => swiper.value.slideNext()

    return {
      prefix: 'the-blockchain-data',
      imageOpen,
      onSwiper,
      nextSlide,
    }
  },
  components: {Swiper, SwiperSlide},
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.the-blockchain-data {
  &__slider.swiper-container {
    padding: 30px 10px;
    margin: -30px -10px;
  }

  &__slide {
    position: relative;
    border: 1px solid $color-moody-blue;
    border-radius: 15px;
    background-color: transparent;

    &-detail {
      background-color: #190D71;
      border: 1px solid #0F0057;
    }

    &::after {
      display: block;
      content: '';
      padding-bottom: 100%;
    }
  }

  &__open {
    position: absolute;
    display: flex;
    gap: 8px;
    right: 14px;
    bottom: 14px;
    font-size: 18px;
    line-height: 21px;
    color: $color-moody-blue;
  }

  &__detail {
    position: absolute;
    top: 6px;
    bottom: 6px;
    left: 6px;
    right: 6px;
    border: 1px solid #4B3EAA;
    border-radius: 15px;
    overflow: hidden;

    &-info {
      position: absolute;
      max-width: 65%;
      right: 0;
      bottom: 0;
      font-size: 18px;
    }

    &-table {
      color: $color-moody-blue;
      padding: 20px;
      line-height: 21px;
    }

    &-tr {
      display: grid;
      grid-template-columns: 55% 45%;
      padding: $row-height * .5 0;
      gap: 24px;
      border-bottom: 1px solid $color-moody-blue;

      &:last-child {
        border-bottom: none;
      }
    }

    &-button {
      display: flex;
      align-items: center;
      background-color: #4B3EAA;
      color: #190D71;
      padding: 8px 16px;
      border-radius: 15px 0 0 0;
      gap: 10px;
      text-decoration: none;

      &:hover {
        color: #0F0057;
      }
    }

    &-in {
      display: inline-block;
      margin-left: 3px;
      margin-bottom: -3px;
      border-bottom: 3px solid $color-moody-blue;
      animation: 1.5s infinite inputPending;
      animation-fill-mode: both;
    }
  }

  @keyframes inputPending {
    0% {
      opacity: 0;
      width: 0;
    }
    1% {
      width: 10px;
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>

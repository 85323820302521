<template>
  <the-header class="app__the-header"/>
  <first-screen class="app__first-screen"/>
  <gallery class="app__gallery"/>
  <technology class="app__technology"/>
  <features class="app__features"/>
  <protection class="app__protection"/>
  <authorship class="app__authorship"/>
  <royalty class="app__royalty"/>
  <editor class="app__editor"/>
  <eco class="app__eco"/>
  <file-types class="app__file-types"/>
  <nft-process class="app__nft-process"/>
  <faq class="app__faq"/>
  <start-nft class="app__start-nft"/>
  <the-footer class="app__the-footer"/>
</template>

<script>
import TheHeader from './components/TheHeader'
import TheFooter from './components/TheFooter'
import FirstScreen from './views/FirstScreen'
import Gallery from './views/Gallery'
import Features from './views/Features'
import Protection from './views/Protection'
import Authorship from './views/Authorship'
import Royalty from './views/Royalty'
import Editor from './views/Editor'
import FileTypes from './views/FileTypes'
import NftProcess from './views/NftProccess'
import Faq from './views/Faq'
import StartNft from './views/StartNft'
import Technology from './views/Technology'
import Eco from './views/Eco'
import {onMounted} from 'vue'
import {useI18n} from 'vue-i18n'

export default {
  components: {
    Eco,
    Technology,
    StartNft,
    Faq,
    NftProcess,
    FileTypes,
    Editor,
    Royalty,
    Authorship,
    Protection,
    Features,
    Gallery,
    FirstScreen,
    TheFooter,
    TheHeader,
  },
  setup() {
    const languages = ['ru', 'en']
    const {locale} = useI18n({useScope: 'global'})
    onMounted(() => {
      if (window.location.pathname) {
        const language = window.location.pathname.slice(1)
        if (languages.includes(language)) {
          locale.value = language
          localStorage.setItem('locale', language)
        }
      }
    })
  },
}
</script>

<style lang="scss">
@import "assets/scss/variables";

.app {
  &__the-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
  }

  &__gallery {
    padding-top: $row-height * 8;
  }

  &__technology {
    padding-top: $row-height * 8;
  }

  &__features {
    padding-top: $row-height * 3;
    padding-bottom: $row-height * 7;
  }

  &__protection {
    padding-bottom: $row-height * 3;
  }

  &__authorship {
    padding-top: $row-height * 7;
  }

  &__royalty {
    padding-top: $row-height * 7;
  }

  &__editor {
    padding-top: $row-height * 6;
  }

  &__eco {
    padding-top: $row-height * 6;
  }

  &__file-types {
    padding-top: $row-height * 6;
  }

  &__nft-process {
    padding-top: $row-height * 8;
  }

  &__faq {
    padding-top: $row-height * 8;
  }

  &__start-nft {
    padding-top: $row-height * 6;
    padding-bottom: $row-height * 7;
  }
}
</style>

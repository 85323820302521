<template>
  <nav :class="prefix">
    <ul :class="`${prefix}__list`">
      <li :class="`${prefix}__item`" v-for="link in links" :key="link.name">
        <base-link type="action" @action="scroll(link.name)" passive>
          {{ $t(`${prefix}.${link.name}`) }}
        </base-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import BaseLink from './Base/BaseLink'

export default {
  name: 'TheNavbar',
  components: {BaseLink},
  setup() {
    const links = [
      {name: 'erachain'},
      {name: 'marketplace'},
      {name: 'faq'},
    ]
    const scroll = name => {
      const el = document.getElementById(name)
      if (el instanceof HTMLElement) {
        console.log('name', name)
        el.scrollIntoView({behavior: 'smooth'})
      }
    }
    return {
      prefix: 'the-navbar',
      links,
      scroll,
    }
  },
}
</script>

<style lang="scss">
.the-navbar {
  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 0 48px;
  }

  &__item {
    display: inline-block;
  }
}
</style>

<template>
  <section :class="prefix" ref="nftProcess">
    <div class="container">
      <div class="row">
        <header :class="`${prefix}__header col-md-8 offset-md-2`">
          <base-title>{{ $t(`${prefix}.title`) }}</base-title>
        </header>
      </div>
      <nft-process-step-one :class="`${prefix}__step ${prefix}__step-one`"/>
      <nft-process-step-two :class="`${prefix}__step ${prefix}__step-two`"/>
      <nft-process-step-three :class="`${prefix}__step ${prefix}__step-three`"/>
    </div>
  </section>
</template>

<script>
import BaseTitle from '../components/Base/BaseTitle'
import NftProcessStepOne from '../components/NftProccess/NftProccessStepOne'
import NftProcessStepTwo from '../components/NftProccess/NftProccessStepTwo'
import NftProcessStepThree from '../components/NftProccess/NftProccessStepThree'

export default {
  name: 'NftProcess',
  components: {NftProcessStepThree, NftProcessStepTwo, NftProcessStepOne, BaseTitle},
  setup() {
    return {
      prefix: 'nft-process',
    }
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.nft-process {
  &__header {
    text-align: center;
    margin-bottom: $row-height * 3;
  }

  &__ul {
    margin: $row-height * 2 0;
  }

  &__step {
    opacity: 0;
  }

  &__step-one, &__step-two {
    padding-bottom: $row-height * 2;
    border-bottom: 1px solid #383649;
    margin-bottom: $row-height * 2;
  }
}
</style>

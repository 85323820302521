<template>
  <section :class="prefix">
    <div class="container">
      <div :class="`${prefix}__table`" ref="table">
        <div :class="`${prefix}__thead`">
          <base-title color="gradient">{{ $t(`${prefix}.title`) }}</base-title>
        </div>
        <div :class="`${prefix}__tbody`">
          <div :class="`${prefix}__col`">
            <div :class="`${prefix}__col-header`">
              <base-title :class="`${prefix}__col-title`" color="pink" size="small">
                {{ $t(`${prefix}.item-1.title`) }}
              </base-title>
              <div :class="`${prefix}__col-icon`">
                <img :src="icon1" alt="Icon 1">
              </div>
            </div>
            <div :class="`${prefix}__col-desc`">{{ $t(`${prefix}.item-1.desc`) }}</div>
          </div>
          <div :class="`${prefix}__col`">
            <div :class="`${prefix}__col-header`">
              <base-title :class="`${prefix}__col-title`" color="pink" size="small">
                {{ $t(`${prefix}.item-2.title`) }}
              </base-title>
              <div :class="`${prefix}__col-icon`">
                <img :src="icon2" alt="Icon 2">
              </div>
            </div>
            <div :class="`${prefix}__col-desc`">{{ $t(`${prefix}.item-2.desc`) }}</div>
          </div>
          <div :class="`${prefix}__col`">
            <div :class="`${prefix}__col-header`">
              <base-title :class="`${prefix}__col-title`" color="pink" size="small">
                {{ $t(`${prefix}.item-3.title`) }}
              </base-title>
              <div :class="`${prefix}__col-icon`">
                <img :src="icon3" alt="Icon 3">
              </div>
            </div>
            <div :class="`${prefix}__col-desc`">
              {{ $t(`${prefix}.item-3.desc`) }}
            </div>
            <base-link :class="`${prefix}__col-link`" color="blue" type="action" @action="openModal">
              {{ $t(`${prefix}.item-3.link`) }}
            </base-link>
            <teleport to="body">
              <base-modal v-if="openedModal" @close="closeModal">
                <the-affiliate-program/>
              </base-modal>
            </teleport>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseTitle from '../components/Base/BaseTitle'
import BaseLink from '../components/Base/BaseLink'
import {onMounted, ref} from 'vue'
import {gsap} from '../myGsap'
import BaseModal from '../components/Base/BaseModal'
import TheAffiliateProgram from '../components/TheAffiliateProgram'
import {useModal} from '../use/modal'

export default {
  name: 'Royalty',
  components: {TheAffiliateProgram, BaseModal, BaseLink, BaseTitle},
  setup() {
    const table = ref({})
    const icon1 = require('../assets/images/royalty-icon-1.png')
    const icon2 = require('../assets/images/royalty-icon-2.png')
    const icon3 = require('../assets/images/royalty-icon-3.png')

    const {openedModal, openModal, closeModal} = useModal()

    onMounted(() => {
      if (table.value instanceof HTMLElement) {
        setTimeout(() => {
          gsap.to(table.value, {
            opacity: 1,
            scrollTrigger: {
              trigger: table.value,
              start: 'top+=100px center',
            },
            duration: 0.9,
            ease: 'none',
          })
        }, 1000)
      }
    })

    return {
      prefix: 'royalty',
      table,
      icon1,
      icon2,
      icon3,
      openedModal,
      openModal,
      closeModal,
    }
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.royalty {
  &__table {
    opacity: 0;
    background: rgb(240, 2, 109);
    background: linear-gradient(45deg, rgba(240, 2, 109, 1) 0%, rgba(7, 82, 232, 1) 100%);
    border-radius: 23px;
    padding: 1px;
  }

  &__thead {
    position: relative;
    display: flex;
    padding: $row-height 0;
    align-items: center;
    justify-content: center;
    background-color: $color-background;
    border-radius: 23px 23px 0 0;
  }

  &__tbody {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1px;
    margin-top: 1px;
    border-radius: 0 0 23px 23px;
    overflow: hidden;
  }

  &__col {
    background-color: $color-background;
    padding-bottom: $row-height * 2;

    &-header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $row-height;
    }

    &-title {
      padding: $row-height * 1.5 0 0 $row-height;
    }

    &-icon {
      position: relative;
      background: rgb(240, 2, 109);
      background: linear-gradient(45deg, rgba(240, 2, 109, 1) 0%, rgba(7, 82, 232, 1) 100%);
      overflow: hidden;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        left: 1px;
        bottom: 1px;
        background-color: $color-background;
      }

      img {
        position: relative;
        margin: -20% -10%;
      }
    }

    &-desc {
      padding: $row-height $row-height 0;
      font-size: 20px;
      line-height: 26px;
    }

    &-link {
      margin: $row-height 0 0 $row-height;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.05em;
    }
  }
}
</style>

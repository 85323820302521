<template>
  <section :class="prefix" id="marketplace">
    <div class="container">
      <header :class="`${prefix}__header`">
        <base-title>{{ $t(`${prefix}.title`) }}</base-title>
      </header>
      <swiper
        :class="`${prefix}__slider`"
        :slides-per-view="4"
        :space-between="24"
        navigation
      >
        <swiper-slide v-for="{key, name, creator, imageURL} in nftList" :key="key">
          <gallery-item :item="{key, name, creator, imageURL}"/>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import 'swiper/components/navigation/navigation.scss'
import {Swiper, SwiperSlide} from 'swiper/vue'
import SwiperCore, {Navigation} from 'swiper'
import BaseTitle from '../components/Base/BaseTitle'
import GalleryItem from '../components/Gallery/GalleryItem'
import {onMounted, ref} from 'vue'
import requests from '../requests'

SwiperCore.use([Navigation])

export default {
  name: 'Gallery',
  setup() {
    const nftList = ref([])
    const getNft = async () => {
      try {
        return (await requests.getNft()).data.filter(l => l.type_key === 65)
      } catch (e) {
        return []
      }
    }
    onMounted(async () => {
      nftList.value = await getNft()
    })
    return {
      prefix: 'gallery',
      nftList,
    }
  },
  components: {GalleryItem, BaseTitle, Swiper, SwiperSlide},
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.gallery {
  &__header {
    margin-bottom: $row-height * 2;
    text-align: center;
  }

  &__slider {
    padding: 0 20px;

    &.swiper-container {
      padding: 0 80px;
      margin: 0 -80px;
    }

    .swiper-slide {
      height: auto;
    }

    .swiper-button-prev, .swiper-button-next {
      height: 100%;
      margin-top: 0;
      top: 0;
      width: 80px;
      display: flex;
      justify-content: flex-start;
      background-color: $color-background;
      transition: $transition-default;
      color: white;

      &::after {
        font-size: 60px;
      }

      &:hover {
        color: $color-pink;
      }

      &.swiper-button-disabled {
        opacity: 1;
        color: $color-background;
      }
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next {
      justify-content: flex-end;
      right: 0;
    }
  }
}
</style>

<template>
  <div class="the-cube">
    <div class="the-cube__container">
      <div class="the-cube__trigger" v-for="idx in 400" :key="idx"/>
      <div class="the-cube__monitor">
        <div class="the-cube__camera o-x">
          <div class="the-cube__camera o-y">
            <div class="the-cube__camera o-z">
              <div class="the-cube__vr">
                <div class="the-cube__vr-layer" v-for="idx in 20" :key="idx">
                  <div class="the-cube__vr-layer-item"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../assets/scss/variables";

$size: 350px;
$layer: 20;

.the-cube {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;

  &__container {
    position: relative;
    display: grid;
    width: 100%;
    grid-template-rows: repeat(20, 25px);
    grid-template-columns: repeat(20, 5%);
    transform-style: preserve-3d;
  }

  &__monitor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transform-style: preserve-3d;
  }

  &__trigger {
    &:active ~ .the-cube__monitor {
      .the-cube__vr-layer-item {
        width: 40%;
        height: 40%;
        border-radius: 5px;
      }
    }

    @for $i from 1 through 20 {
      @for $j from 1 through 20 {
        $key: ($i - 1) * 20 + $j;

        &:nth-child(#{$key}) {
          &:hover ~ .the-cube__monitor {
            .the-cube__camera {
              &.o-y {
                transform: rotateY(($j - 10) * -8deg);
              }

              &.o-x {
                transform: rotateX(($i - 10) * 8deg);
              }
            }
          }
        }
      }
    }
  }

  &__camera {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: 500ms;

    &.o-y {
      transform: rotateY(-30deg);
    }
  }

  &__vr {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $size;
    height: $size;
    transform: translate(-50%, -50%);
    transform-style: preserve-3d;

    &-layer {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border: 1px solid rgba(#fff, 0.2);
      background: rgba(#09f, 0.006);
      border-radius: 10px;
      //noinspection CssInvalidPropertyValue
      transform: preserve-3d;

      @for $i from 1 through $layer {
        &:nth-child(#{$i}) {
          transform: translateZ($i * $size / $layer - $size / 2);
        }

        &:nth-child(#{$i}) &-item {
          animation-delay: $i * -210ms;
        }
      }

      &-item {
        width: 70%;
        height: 70%;
        border: 3px solid #fff;
        border-radius: 100%;
        background: rgba(#000, 0.05);
        animation: sphere 3000ms cubic-bezier(0.215, 0.610, 0.355, 1.000) alternate infinite, color 5000ms linear alternate infinite;
        transition: 500ms;
      }
    }
  }
}

@keyframes sphere {
  0% {
    transform: scale(0) rotateZ(45deg);
  }

  50% {
    transform: scale(0) rotateZ(45deg);
  }

  100% {
    transform: scale(1) rotateZ(45deg);
  }
}

@keyframes color {
  0% {
    border-color: #098ba7;
  }

  50% {
    border-color: #ce00a6;
  }

  100% {
    border-color: $color-pink;
  }
}
</style>

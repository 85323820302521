<template>
  <section :class="prefix">
    <div class="container" :ref="prefix">
      <div class="row">
        <div :class="`${prefix}__tablet col-md-6`">
          <img :src="imageTablet" alt="Tablet" ref="tablet">
        </div>
        <div :class="`${prefix}__info col-md-6`" ref="info">
          <header :class="`${prefix}__header`">
            <base-title :class="`${prefix}__title`">{{ $t(`${prefix}.title`) }}</base-title>
          </header>
          <div :class="`${prefix}__description`">
            <span>{{ $t(`${prefix}.desc-1`) }}</span>
            {{ $t(`${prefix}.desc-2`) }}
          </div>
          <div :class="`${prefix}__note`">* {{ $t(`${prefix}.note`) }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseTitle from '../components/Base/BaseTitle'
import {onMounted, ref} from 'vue'
import {gsap} from '../myGsap'

export default {
  name: 'Authorship',
  components: {BaseTitle},
  setup() {
    const imageTablet = require('../assets/images/tablet.png')
    const authorship = ref({})
    const info = ref({})
    const tablet = ref({})

    onMounted(() => {
      if (authorship.value instanceof HTMLElement) {
        const scrollTrigger = {
          trigger: authorship.value,
          start: 'top+=100px center',
        }

        setTimeout(() => {
          gsap.to(tablet.value, {
            opacity: 1,
            translateX: 0,
            scrollTrigger,
            duration: 0.5,
            ease: 'none',
          })
          gsap.to(info.value, {
            opacity: 1,
            scrollTrigger,
            duration: 0.9,
            delay: 0.5,
            ease: 'none',
          })
        }, 1000)
      }
    })

    return {
      prefix: 'authorship',
      imageTablet,
      authorship,
      info,
      tablet,
    }
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.authorship {
  background: rgb(7, 3, 34);
  background: linear-gradient(0deg, rgba(7, 3, 34, 1) 0%, rgba(0, 0, 0, 1) 100%);

  &__tablet {
    padding-right: $row-height * 3 !important;

    img {
      opacity: 0;
      transform: translateX(-100px);
      max-width: 100%;
    }
  }

  &__info {
    opacity: 0;
  }

  &__description {
    margin: $row-height 0;
    font-size: 28px;
    line-height: 33px;

    span {
      display: block;
      font-weight: bold;
      color: #31D0DA;
    }
  }

  &__note {
    color: $color-chetwode-blue;
  }
}
</style>

<template>
  <section :class="prefix">
    <div class="container" ref="fileTypes">
      <div class="row">
        <header :class="`${prefix}__header col-md-10 offset-md-1`">
          <base-title>{{ $t(`${prefix}.title`) }}</base-title>
        </header>
      </div>
      <div :class="`${prefix}__types`">
        <div :class="`${prefix}__item ${prefix}__item_image`">
          <div :class="`${prefix}__item-image`">
            <img :src="typeImage" alt="Type image">
          </div>
          <div :class="`${prefix}__item-header`">
            <div :class="`${prefix}__item-title`">{{ $t(`${prefix}.type-1.title`) }}</div>
            <div :class="`${prefix}__item-desc`">{{ $t(`${prefix}.type-1.desc`) }}</div>
          </div>
        </div>
        <div :class="`${prefix}__item ${prefix}__item_gif`">
          <div :class="`${prefix}__item-image`">
            <the-cat/>
          </div>
          <div :class="`${prefix}__item-header`">
            <div :class="`${prefix}__item-title`">{{ $t(`${prefix}.type-2.title`) }}</div>
            <div :class="`${prefix}__item-desc`">&nbsp;</div>
          </div>
        </div>
        <div :class="`${prefix}__item ${prefix}__item_video`">
          <div :class="`${prefix}__item-image`">
            <base-video :src="videoURL" ref="video"/>
          </div>
          <div :class="`${prefix}__item-header`">
            <div :class="`${prefix}__item-title`">{{ $t(`${prefix}.type-3.title`) }}</div>
            <div :class="`${prefix}__item-desc`">{{ $t(`${prefix}.type-3.desc`) }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseTitle from '../components/Base/BaseTitle'
import TheCat from '../components/TheCat'
import BaseVideo from '../components/Base/BaseVideo'
import {onMounted, ref} from 'vue'
import {gsap} from '../myGsap'

export default {
  name: 'FileTypes',
  components: {BaseVideo, TheCat, BaseTitle},
  setup() {
    const fileTypes = ref({})
    const video = ref({})
    const typeImage = require('../assets/images/type-image.png')
    const videoURL = require('../assets/video/protocol-30-seconds.webm')

    onMounted(() => {
      if (fileTypes.value instanceof HTMLElement) {
        setTimeout(() => {
          gsap.to(fileTypes.value, {
            opacity: 1,
            scrollTrigger: {
              trigger: fileTypes.value,
              start: 'top+=100px center',
            },
            duration: 0.9,
            ease: 'none',
            onComplete: () => video.value.play(),
          })
        }, 1000)
      }
    })

    return {
      prefix: 'file-types',
      typeImage,
      fileTypes,
      video,
      videoURL,
    }
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.file-types {
  & > .container {
    opacity: 0;
  }

  &__types {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  &__header {
    padding: 0 $row-height * 3 $row-height * 2.5;
    text-align: center;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $color-card-background;
    border-radius: 20px;
    padding-bottom: 18px;

    &_image {
      .file-types__item-image {
        padding: 36px 0;
      }
    }

    &_gif {
      .file-types__item-image {
        padding: 0 20px;
      }
    }

    &_video {
      .base-video {
        overflow: hidden;
        border-radius: 20px 20px 0 0;

        video {
          width: 180%;
          max-width: 180%;
          margin-left: -40%;
        }
      }
    }

    &::before {
      position: absolute;
      content: '';
      border-radius: 20px;
      background: rgb(240, 2, 109);
      background: linear-gradient(45deg, rgba(240, 2, 109, 1) 0%, rgba(7, 82, 232, 1) 100%);
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      z-index: -1;
    }

    & > * {
      position: relative;
    }

    &-header {
      text-align: center;
      margin-top: auto;
    }

    &-title {
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
      color: $color-pink;
    }

    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>

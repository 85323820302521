<template>
  <a
    v-if="type === 'default'"
    :class="['link', {[`link_color-${color}`]: color}]"
    :data-passive="passive"
    :href="href"
    :target="target"
  >
    <slot/>
  </a>
  <span
    v-else-if="type === 'action'"
    :class="['link', {[`link_color-${color}`]: color}]"
    :data-passive="passive"
    @click="$emit('action')"
  >
    <slot/>
  </span>
</template>

<script>
export default {
  name: 'BaseLink',
  emits: ['action'],
  props: {
    type: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'action'].includes(value)
      },
    },
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
      validator(value) {
        return ['', '_blank'].includes(value)
      },
    },
    color: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'blue', 'grey'].includes(value)
      },
    },
    passive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.link {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  border-bottom: 1px dashed currentColor;
  transition: $transition-default;
  padding-bottom: 3px;
  margin-bottom: -3px;

  &[data-passive="true"] {
    border-bottom-color: transparent;
  }

  &:hover {
    color: inherit;
    border-bottom-color: transparent;
    cursor: pointer;

    &[data-passive="true"] {
      border-bottom-color: currentColor;
    }
  }

  &_color {
    &-blue {
      color: $color-chetwode-blue;

      &:hover {
        color: $color-pink;
      }
    }

    &-blue {
      color: $color-chetwode-blue;

      &:hover {
        color: $color-pink;
      }
    }
  }
}
</style>

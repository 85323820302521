<template>
  <svg
    class="icon-info"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8.12439" cy="8.12439" r="7.62439" stroke="#F0026D"/>
    <path
      d="M7.51058 11.8693V6.67469H8.72724V11.8693H7.51058ZM8.1242 5.76484C7.90556 5.76484 7.7257 5.70136 7.58464 5.57441C7.44358 5.44745 7.37305 5.2817 7.37305 5.07716C7.37305 4.87262 7.44358 4.70688 7.58464 4.57992C7.7257 4.44591 7.90556 4.37891 8.1242 4.37891C8.34285 4.37891 8.5227 4.44591 8.66377 4.57992C8.80483 4.70688 8.87536 4.87262 8.87536 5.07716C8.87536 5.2817 8.80483 5.44745 8.66377 5.57441C8.5227 5.70136 8.34285 5.76484 8.1242 5.76484Z"
      fill="#F0026D"/>
  </svg>
</template>

<script>
export default {
  name: 'IconInfo',
}
</script>
<style lang="scss">
.icon-info {
  cursor: pointer;
  margin: 0 5px;
}
</style>

<template>
  <section :class="prefix" id="faq">
    <div class="container" ref="faq">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div :class="`${prefix}__wrapper`">
            <header :class="`${prefix}__header`">
              <base-title :class="`${prefix}__title`">{{ $t(`${prefix}.title`) }}</base-title>
            </header>
            <div :class="`${prefix}__questions`">
              <faq-item
                :class="`${prefix}__questions-item`"
                @choiceOfQuestion="choiceOfQuestion"
                v-for="i in countQuestion"
                :question="i"
                :key="i"
              >
                <template #question>{{ $t(`${prefix}.question-${i}`) }}</template>
                <template #answer v-if="activeQuestion === i">
                  <div v-html="$t(`${prefix}.answer-${i}`)"/>
                </template>
              </faq-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseTitle from '../components/Base/BaseTitle'
import FaqItem from '../components/Faq/FaqItem'
import {onMounted, ref} from 'vue'
import {gsap} from '../myGsap'

export default {
  name: 'Faq',
  components: {FaqItem, BaseTitle},
  setup() {
    const faq = ref({})
    const activeQuestion = ref(1)
    const choiceOfQuestion = idx => {
      activeQuestion.value = idx
    }

    onMounted(() => {
      if (faq.value instanceof HTMLElement) {
        setTimeout(() => {
          gsap.to(faq.value, {
            opacity: 1,
            scrollTrigger: {
              trigger: faq.value,
              start: 'top+=100px center',
            },
            duration: 0.9,
            ease: 'none',
          })
        }, 1000)
      }
    })

    return {
      prefix: 'faq',
      faq,
      countQuestion: 13,
      activeQuestion,
      choiceOfQuestion,
    }
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.faq {
  & > .container {
    opacity: 0;
  }

  &__header {
    text-align: center;
    margin-bottom: $row-height * 2;
  }

  &__wrapper {
    border: 1px solid white;
    border-radius: 23px;
    padding: $row-height * 2;
  }

  &__questions-item {
    border-bottom: 1px solid #BBC8E3;
  }
}
</style>

<template>
  <section :class="prefix">
    <div class="container">
      <div class="row">
        <div :class="`${prefix}__info col-md-6`">
          <header :class="`${prefix}__header`" ref="header">
            <base-title :class="`${prefix}__title`">{{ $t(`${prefix}.title`) }}</base-title>
            <div :class="`${prefix}__description`">
              <p v-html="$t(`${prefix}.description-1`)"/>
              <p v-html="$t(`${prefix}.description-2`, {link1, link2})"/>
            </div>
          </header>
        </div>
        <div :class="`${prefix}__clock col-md-5 offset-md-1`">
          <the-eco-clock/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseTitle from '../components/Base/BaseTitle'
import {onMounted, ref} from 'vue'
import {gsap} from '../myGsap'
import TheEcoClock from '../components/TheEcoClock'

export default {
  name: 'Eco',
  components: {TheEcoClock, BaseTitle},
  setup() {
    const header = ref({})

    onMounted(() => {
      if (header.value instanceof HTMLElement) {
        setTimeout(() => {
          gsap.to(header.value, {
            opacity: 1,
            scrollTrigger: {
              trigger: header.value,
              start: 'top+=100px center',
            },
            duration: 0.9,
            ease: 'none',
          })
        }, 1000)
      }
    })

    return {
      prefix: 'eco',
      header,
      link1: 'https://interxion.azureedge.net/cdn/ff/JlIbflAPEvRNbI1_NKZO4x0cdjgt5ywQZ353VwV_xwY/1584528224/public/2020-03/energy-efficiency-at-interxion-factsheet.pdf',
      link2: 'https://blog.equinix.com/blog/2021/05/04/the-equinix-2020-sustainability-report/',
    }
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.eco {
  position: relative;

  &__header {
    position: relative;
    opacity: 0;
  }

  &__title {
    margin-bottom: $row-height;
  }

  &__description {
    font-size: 28px;
    line-height: 33px;

    strong {
      color: #31D0DA;
    }
  }

  &__clock {
    margin-top: $row-height * 3!important;
  }
}
</style>

<template>
  <div :class="prefix">
    <div class="row">
      <header :class="`${prefix}__header col-md-8 offset-md-2`">
        <h1 :class="`${prefix}__title`">{{ $t(`${prefix}.title`) }}</h1>
        <div :class="`${prefix}__desc`">{{ $t(`${prefix}.desc`) }}</div>
      </header>
    </div>
    <div class="row">
      <div :class="`${prefix}__content col-md-10 offset-md-1`">
        <base-content>
          <template #title>{{ $t(`${prefix}.subtitle-1`) }}</template>
          <p v-html="$t(`${prefix}.text-1-1`)"/>
          <p v-html="$t(`${prefix}.text-1-2`)"/>
        </base-content>
        <base-content>
          <template #title>{{ $t(`${prefix}.subtitle-2`) }}</template>
          <p v-html="$t(`${prefix}.text-2-1`)"/>
          <ol>
            <li class="mb-2">
              <span v-html="$t(`${prefix}.text-2-2`)"/>
              <base-blockquote>
                <span v-html="$t(`${prefix}.text-2-3`)"/>
              </base-blockquote>
            </li>
            <li class="mb-2">
              <span v-html="$t(`${prefix}.text-2-4`)"/>
              <base-blockquote>
                <span v-html="$t(`${prefix}.text-2-5`)"/>
              </base-blockquote>
            </li>
          </ol>
        </base-content>
        <base-content>
          <template #title>{{ $t(`${prefix}.subtitle-3`) }}</template>
          <p v-html="$t(`${prefix}.text-3-1`)"/>
          <img class="mt-2" :src="affiliateProgram1" alt="Affiliate Program 1">
        </base-content>
        <base-content>
          <template #title>{{ $t(`${prefix}.subtitle-4`) }}</template>
          <p v-html="$t(`${prefix}.text-4-1`)"/>
          <p v-html="$t(`${prefix}.text-4-2`)"/>
          <ol>
            <li><span v-html="$t(`${prefix}.text-4-3`)"/></li>
            <li><span v-html="$t(`${prefix}.text-4-4`)"/></li>
            <li><span v-html="$t(`${prefix}.text-4-5`)"/></li>
          </ol>
          <p v-html="$t(`${prefix}.text-4-6`)"/>
        </base-content>
        <base-content>
          <template #title>{{ $t(`${prefix}.subtitle-5`) }}</template>
          <p v-html="$t(`${prefix}.text-5-1`)"/>
          <img class="mt-2 mb-2" :src="affiliateProgram2" alt="Affiliate Program 2">
          <base-blockquote class="mb-2">
            <span v-html="$t(`${prefix}.text-5-2`)"/>
          </base-blockquote>
          <p v-html="$t(`${prefix}.text-5-3`)"/>
          <div :class="`${prefix}__image_fullwidth mt-2`">
            <img :src="affiliateProgram3" alt="Affiliate Program 3">
          </div>
        </base-content>
        <base-content>
          <template #title>{{ $t(`${prefix}.subtitle-6`) }}</template>
          <p v-html="$t(`${prefix}.text-6-1`)"/>
          <div :class="`${prefix}__image_fullwidth mt-2 mb-2`">
            <img :src="affiliateProgram4" alt="Affiliate Program 4">
          </div>
          <p v-html="$t(`${prefix}.text-6-2`)"/>
          <base-blockquote>
            <span v-html="$t(`${prefix}.text-6-3`)"/>
          </base-blockquote>
        </base-content>
        <base-content>
          <template #title>{{ $t(`${prefix}.subtitle-7`) }}</template>
          <p v-html="$t(`${prefix}.text-7-1`)"/>
          <base-blockquote>
            <span v-html="$t(`${prefix}.text-7-2`)"/>
          </base-blockquote>
          <p v-html="$t(`${prefix}.text-7-3`)"/>
          <ol>
            <li><span v-html="$t(`${prefix}.text-7-4`)"/></li>
            <li><span v-html="$t(`${prefix}.text-7-5`)"/></li>
          </ol>
          <div :class="`${prefix}__image_fullwidth mt-2 mb-2`">
            <img :src="affiliateProgram5" alt="Affiliate Program 5">
          </div>
          <ol start="3">
            <li><span v-html="$t(`${prefix}.text-7-6`)"/></li>
          </ol>
          <base-blockquote>
            <span v-html="$t(`${prefix}.text-7-7`)"/>
          </base-blockquote>
          <p v-html="$t(`${prefix}.text-7-8`)"/>
        </base-content>
      </div>
    </div>
  </div>
</template>

<script>

import BaseContent from './Base/BaseContent'
import BaseBlockquote from './Base/BaseBlockquote'
import {useI18n} from 'vue-i18n'

export default {
  name: 'TheAffiliateProgram',
  components: {BaseBlockquote, BaseContent},
  setup() {
    const {locale} = useI18n()
    const affiliateProgram1 = locale.value === 'en'
      ? require('../assets/images/affiliateProgram-1_en.jpg') : require('../assets/images/affiliateProgram-1.jpg')
    const affiliateProgram2 = locale.value === 'en'
      ? require('../assets/images/affiliateProgram-2_en.jpg') : require('../assets/images/affiliateProgram-2.jpg')
    const affiliateProgram3 = locale.value === 'en'
      ? require('../assets/images/affiliateProgram-3_en.jpg') : require('../assets/images/affiliateProgram-3.jpg')
    const affiliateProgram4 = locale.value === 'en'
      ? require('../assets/images/affiliateProgram-4_en.jpg') : require('../assets/images/affiliateProgram-4.jpg')
    const affiliateProgram5 = locale.value === 'en'
      ? require('../assets/images/affiliateProgram-5_en.jpg') : require('../assets/images/affiliateProgram-5.jpg')
    return {
      prefix: 'the-affiliate-program',
      affiliateProgram1,
      affiliateProgram2,
      affiliateProgram3,
      affiliateProgram4,
      affiliateProgram5,
    }
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.the-affiliate-program {
  overflow-x: hidden;

  &__header {
    text-align: center;
    margin-bottom: $row-height * 3;
  }

  &__title {
    font-weight: 600;
    font-size: 52px;
    line-height: 56px;
    text-align: center;
    color: #0752E8;
    margin-bottom: $row-height * 2;
  }

  &__desc {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: black;
  }

  &__image {
    &_fullwidth {
      img {
        width: 120%;
        margin-left: -10%;
      }
    }
  }
}
</style>

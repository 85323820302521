<template>
  <li class="base-list-item">
    <slot/>
  </li>
</template>

<script>
export default {
  name: 'BaseListItem',
}
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.base-list-item {
  margin-bottom: $row-height * 0.5;

  p {
    margin-top: $row-height * 0.25;
  }
}
</style>

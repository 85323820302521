<template>
  <section :class="prefix" ref="step">
    <div class="row">
      <div :class="`${prefix}__info col-md-5`">
        <base-title :class="`${prefix}__title`" size="small">
          {{ $t(`${prefix}.title`) }}
        </base-title>
        <base-list>
          <base-list-item>
            <strong>{{ $t(`${prefix}.li-1.title`) }}</strong>
            <p>{{ $t(`${prefix}.li-1.desc`) }}</p>
          </base-list-item>
          <base-list-item>
            <strong>{{ $t(`${prefix}.li-2.title`) }}</strong>
            <p>{{ $t(`${prefix}.li-2.desc`) }}</p>
          </base-list-item>
        </base-list>
        <base-blockquote :class="`${prefix}__ps`">{{ $t(`${prefix}.ps`) }}</base-blockquote>
      </div>
      <div :class="`${prefix}__info col-md-7`">
        <base-video-border>
          <base-video :src="video"/>
        </base-video-border>
      </div>
    </div>
  </section>
</template>

<script>
import BaseTitle from '../Base/BaseTitle'
import BaseVideo from '../Base/BaseVideo'
import {computed, onMounted, ref} from 'vue'
import {useI18n} from 'vue-i18n'
import BaseVideoBorder from '../Base/BaseVideoBorder'
import BaseBlockquote from '../Base/BaseBlockquote'
import BaseList from '../Base/BaseList'
import BaseListItem from '../Base/BaseListItem'
import {gsap} from '../../myGsap'

export default {
  name: 'NftProcessStepOne',
  components: {BaseListItem, BaseList, BaseBlockquote, BaseVideoBorder, BaseVideo, BaseTitle},
  setup() {
    const step = ref({})
    const {locale} = useI18n()

    const video = computed(() => {
      return locale.value === 'ru'
        ? require('../../assets/video/create-account-ru.webm')
        : require('../../assets/video/create-account-en.webm')
    })

    onMounted(() => {
      if (step.value instanceof HTMLElement) {
        setTimeout(() => {
          gsap.to(step.value, {
            opacity: 1,
            scrollTrigger: {
              trigger: step.value,
              start: 'top+=100px center',
            },
            duration: 0.9,
            ease: 'none',
          })
        }, 1000)
      }
    })

    return {
      prefix: 'nft-process-step-one',
      video,
      step,
    }
  },
}
</script>
<style lang="scss">
@import "../../assets/scss/variables";

.nft-process-step-one {
  font-size: 20px;
  line-height: 26px;
}
</style>

<template>
  <div v-if="type === 'yay'" class="emoji emoji--yay">
    <div class="emoji__face">
      <div class="emoji__eyebrows"></div>
      <div class="emoji__mouth"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IconEmoji',
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['yay'].includes(value)
      },
    },
  },
}
</script>

<style lang="scss">
$emoji-base-color: #FFDA6A;
$emoji-like-color: #548DFF;
$emoji-love-color: #F55064;
$emoji-angry-color: #D5234C;
$emoji-white-color: #FFFFFF;
$emoji-black-color: #000000;
$emoji-text-color: #8A8A8A;

.emoji {
  width: 120px;
  height: 120px;
  background: $emoji-base-color;
  display: inline-block;
  border-radius: 50%;
  position: relative;

  &:after {
    position: absolute;
    bottom: -40px;
    font-size: 18px;
    width: 60px;
    left: calc(50% - 30px);
    color: $emoji-text-color;
  }
}

.emoji__face, .emoji__eyebrows, .emoji__mouth {
  position: absolute;

  &:before, &:after {
    position: absolute;
    content: '';
  }
}

.emoji__face {
  width: inherit;
  height: inherit;
}

.emoji--yay {
  .emoji__face {
    animation: yay 1s linear infinite alternate;
  }

  .emoji__eyebrows {
    left: calc(50% - 3px);
    top: 30px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: transparent;
    box-shadow: -6px 0 0 0 $emoji-black-color, -36px 0 0 0px $emoji-black-color, 6px 0 0 0 $emoji-black-color, 36px 0 0 0px $emoji-black-color;

    &:before, &:after {
      width: 36px;
      height: 18px;
      border-radius: 60px 60px 0 0;
      background: transparent;
      border: 6px solid black;
      box-sizing: border-box;
      border-bottom: 0;
      bottom: 3px;
      left: calc(50% - 18px);
    }

    &:before {
      margin-left: -21px;
    }

    &:after {
      margin-left: 21px;
    }
  }

  .emoji__mouth {
    top: 60px;
    background: transparent;
    left: 50%;

    &:after {
      width: 80px;
      height: 80px;
      left: calc(50% - 40px);
      top: -75px;
      border-radius: 50%;
      background: transparent;
      border: 6px solid $emoji-black-color;
      box-sizing: border-box;
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      z-index: 1;
    }

    &:before {
      width: 6px;
      height: 6px;
      background: transparent;
      border-radius: 50%;
      bottom: 5px;
      left: calc(50% - 3px);
      box-shadow: -25px 0 0 0 $emoji-black-color, 25px 0 0 0 $emoji-black-color, -35px -2px 30px 10px $emoji-angry-color, 35px -2px 30px 10px $emoji-angry-color;
    }
  }
}

@keyframes yay {
  25% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
}
</style>

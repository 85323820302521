<template>
  <div :class="prefix" @click="changeLocale">
    {{ locale }}
  </div>
</template>

<script>
import {useI18n} from 'vue-i18n'

export default {
  name: 'TheLanguage',
  setup() {
    const {locale} = useI18n({useScope: 'global'})
    const changeLocale = () => {
      const language = locale.value === 'ru' ? 'en' : 'ru'
      locale.value = language
      localStorage.setItem('locale', language)
    }

    return {
      prefix: 'the-language',
      changeLocale,
      locale,
    }
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.the-language {
  cursor: pointer;
  text-transform: capitalize;
  transition: $transition-default;

  &:hover {
    color: $color-pink;
  }
}
</style>

<template>
  <div :class="prefix">
    <div id="wrap">
      <div class="inner">
        <div class="filtered">
          <div class="frame"></div>
          <div class="clouds"></div>
        </div>
      </div>
    </div>
    <svg id="blob" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="wave">
          <feTurbulence
            baseFrequency="0.004172919425741561 0.0071895831106207325"
            ref="turbulence"
            numOctaves="1"
            result="noise"
            seed="13"
          ></feTurbulence>
          <feDisplacementMap id="displacement" in2="noise" in="SourceGraphic" scale="126"></feDisplacementMap>
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue'

export default {
  name: 'BackgroundWave',
  setup() {
    const turbulence = ref({})

    onMounted(() => {
      if (turbulence.value instanceof SVGFETurbulenceElement) {
        let frames = 0
        const rad = Math.PI / 200

        const freqAnimation = () => {
          let bfx = 0.005
          let bfy = 0.01
          frames += 0.45
          bfx += 0.001 * Math.cos(frames * rad)
          bfy += 0.005 * Math.sin(frames * rad)

          const bf = bfx.toString() + ' ' + bfy.toString()
          turbulence.value.setAttributeNS(null, 'baseFrequency', bf)
          window.requestAnimationFrame(freqAnimation)
        }

        window.requestAnimationFrame(freqAnimation)
      }
    })
    return {
      prefix: 'background-wave',
      turbulence,
    }
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.background-wave {
  $p: #f0026d;
  $y: #7923a9;
  $b: #0752eb;
  $pu: #070121;
  $w: #c0cdd7;
  $t: transparent;

  position: absolute;
  width: 100%;
  height: 100%;

  svg {
    position: absolute;
    width: 0;
    height: 0;
  }

  #wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    z-index: 1;

    .inner {
      position: relative;
      overflow: hidden;
      box-shadow: 0 15px 40px -5px rgba(0, 0, 0, 0.5);
      transform: scale(3);

      .filtered {
        position: absolute;
        width: 200%;
        height: 200%;
        overflow: hidden;
        top: -50%;
        left: -50%;
        filter: url(#wave);
      }
    }
  }

  .frame, .clouds {
    content: '';
    position: absolute;
    width: 900000px;
    height: 900000px;
    top: -900px;
    left: -900px;
    transform-origin: 0 0;
    transform: scale(0.525);
    background: repeating-linear-gradient(-12deg, $w 0px, $p 150px, $y 200px, $b 400px, $pu 500px, $b 700px, $y 750px, $p 800px, $b 850px, $w 1100px);
    //background: repeating-linear-gradient(-12deg, $w 0px, $p 150px, $y 200px, $b 200px, $pu 400px, $b 700px, $y 750px, $p 800px, $b 850px, $w 1100px);
  }

  .clouds {
    background: repeating-linear-gradient(22deg, $pu 100px, $t 390px, $pu 400px);
    //background: repeating-linear-gradient(22deg, $pu 85px, $t 395px, $pu 420px);
    transform: scale(0.425);
  }
}
</style>

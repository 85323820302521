<template>
  <header :class="prefix">
    <div :class="`container ${prefix}__wrapper`">
      <div :class="`${prefix}__logo`" v-if="logo">
        <img :src="logo" alt="Erachain">
      </div>
      <div :class="`${prefix}__navbar`">
        <the-navbar/>
        <the-language/>
      </div>
    </div>
  </header>
</template>

<script>
import TheLanguage from './TheLanguage'
import TheNavbar from './TheNavbar'

export default {
  name: 'TheHeader',
  components: {TheNavbar, TheLanguage},
  setup() {
    const logo = require('../assets/images/logo.svg')
    return {
      prefix: 'the-header',
      logo,
    }
  },
}
</script>

<style lang="scss">
.the-header {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
  }

  &__logo {
    img {
      height: 60px;
      width: auto;
    }
  }

  &__navbar {
    display: flex;
    gap: 0 48px;
  }
}
</style>

<template>
  <section class="the-token-info">
    <base-modal @close="$emit('close')">
      <base-content>
        <template #title>{{ $t(`${prefix}.title`) }}</template>
        <p v-html="$t(`${prefix}.text-1`)"/>
        <p v-html="$t(`${prefix}.text-2`)"/>
        <p v-html="$t(`${prefix}.text-3`)"/>
        <p v-html="$t(`${prefix}.text-4`)"/>
        <p v-html="$t(`${prefix}.text-5`)"/>
      </base-content>
    </base-modal>
  </section>
</template>

<script>
import BaseContent from './Base/BaseContent'
import BaseModal from './Base/BaseModal'

export default {
  name: 'TheTokenInfo',
  emits: ['close'],
  components: {BaseModal, BaseContent},
  setup() {
    return {
      prefix: 'the-token-info',
    }
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.the-token-info {
  .base-modal {
    align-items: center;

    &__wrapper {
      background-color: #1E1A38;
      color: #C6C3D9;
      padding: $row-height $row-height 0;
      max-width: 500px;
    }

    &__close {
      top: 16px;
      right: 16px;

      svg {
        width: 19px;

        circle {
          fill: white;
        }

        path {
          fill: #1E1A38;
        }
      }
    }
  }

  .base-content {
    margin-bottom: 0;

    &__title {
      font-size: 28px;
      line-height: 26px;
      color: white;
    }
  }
}

</style>

<template>
  <div :class="prefix" ref="galleryItem">
    <div :class="`${prefix}__image`" :style="`height: ${widthElement}px`">
      <img :src="mediaURL" :alt="item.name">
    </div>
    <div :class="`${prefix}__info`">
      <div :class="`${prefix}__name`">{{ item.name ?? '' }}</div>
      <div :class="`${prefix}__author`">{{ nftCreatorName }}</div>
    </div>
    <div :class="`${prefix}__buy`">
      <div :class="`${prefix}__price`">
        <div :class="`${prefix}__price-label`">{{ $t('gallery.price') }}</div>
        <div :class="`${prefix}__price-value`">{{ nftPrice ? nftPrice + ' ERA' : '&mdash;' }}</div>
      </div>
      <base-button
        color="blue"
        size="small"
        responsive
        :href="`https://web.erachain.org/services/nft/item/${item.key}`"
      >{{ $t('gallery.button') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from '../Base/BaseButton'
import {onMounted, ref} from 'vue'
import requests from '../../requests'

export default {
  name: 'GalleryItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const galleryItem = ref({})
    const mediaURL = ref('')
    const nftCreatorName = ref('')
    const widthElement = ref(0)
    const nftPrice = ref(0)

    const getPersonByAddress = address => {
      return requests.getPersonByAddress(address).then(p => p.data.name ?? address)
    }

    const getMediaUrl = image => {
      return image.charAt(0) === '/' ? requests.baseURL + image : image
    }

    const getPrice = async nftKey => {
      try {
        const orderBook = (await requests.getOrderBook(nftKey, 1)).data
        if (orderBook.have.length > 0) return orderBook.have[0].pairPrice
        if (orderBook.want.length > 0) return orderBook.want[0].pairPrice

        const dealHistory = (await requests.getLastTrade(nftKey, 1)).data
        if (dealHistory.length > 0) return dealHistory[0].price

        throw new Error('Цена не найдена')
      } catch (e) {
        return 0
      }
    }

    onMounted(async () => {
      if (galleryItem.value instanceof HTMLElement) {
        setTimeout(() => {
          widthElement.value = galleryItem.value.getBoundingClientRect().width
        }, 500)
      }

      mediaURL.value = getMediaUrl(props.item.imageURL)
      nftCreatorName.value = await getPersonByAddress(props.item.creator)
      nftPrice.value = await getPrice(props.item.key)
    })

    return {
      prefix: 'gallery-item',
      galleryItem,
      widthElement,
      mediaURL,
      nftCreatorName,
      nftPrice,
    }
  },
  components: {BaseButton},
}
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.gallery-item {
  background-color: #1E1B38;
  border: 1px solid #3D3B54;
  border-radius: 15px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__info {
    padding: 20px 10px;
    font-size: 15px;
    line-height: 18px;
  }

  &__buy {
    margin-top: auto;
    padding: 0 10px 20px;
    font-size: 15px;
    line-height: 18px;
  }

  &__author {
    color: $color-chetwode-blue;
  }

  &__price {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    line-height: 28px;
    text-transform: lowercase;
    margin: 0 0 $row-height;

    &-label {
      font-weight: 300;
    }

    &-value {
      font-weight: bold;
    }
  }
}
</style>

<template>
  <blockquote class="base-blockquote">
    <slot/>
  </blockquote>
</template>

<script>
export default {
  name: 'BaseBlockquote',
}
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.base-blockquote {
  padding-left: $row-height / 2;
  margin-left: $row-height / 2;
  border-left: 2px solid white;
}
</style>

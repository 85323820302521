<template>
  <div class="base-video-border">
    <slot/>
  </div>
</template>

<style lang="scss">
.base-video-border {
  border-radius: 17px;
  background: #f0026d;
  background: linear-gradient(45deg, #f0026d 0%, #0752e8 100%);
  padding: 4px;

  .base-video {
    border-radius: 15px;
    overflow: hidden;
  }
}
</style>

<template>
  <section class="base-modal" @click="$emit('close')">
    <div class="base-modal__wrapper" @click.stop>
      <div class="base-modal__close" @click="$emit('close')">
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="11.5" cy="11.5" r="11.5"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.499 9.74845L14.8721 6.37537C16.025 5.22254 17.7755 6.98736 16.6227 8.14019L13.2639 11.499L16.6227 14.8721C17.7755 16.025 16.025 17.7755 14.8721 16.6227L11.499 13.2639L8.14019 16.6227C6.98736 17.7755 5.22254 16.025 6.3896 14.8721L9.74845 11.499L6.37537 8.14019C5.22254 6.98736 6.98736 5.22254 8.14019 6.37537L11.499 9.74845Z"
                fill="#F8F8FA"/>
        </svg>
      </div>
      <perfect-scrollbar>
        <slot/>
      </perfect-scrollbar>
    </div>
  </section>
</template>

<script>
import {PerfectScrollbar} from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

export default {
  name: 'BaseModal',
  emits: ['close'],
  components: {PerfectScrollbar},
}
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.base-modal {
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 99999999;

  a, a:hover {
    color: currentColor;
  }

  &__wrapper {
    position: relative;
    max-width: 830px;
    width: 100%;
    background-color: white;
    color: black;
    padding: $row-height * 3 $row-height;
    border-radius: 20px;
    max-height: 90vh;
    margin-top: 5vh;

    .ps {
      overflow-x: hidden;
      max-height: 80vh;
    }
  }

  &__close {
    position: absolute;
    top: $row-height;
    right: $row-height;
    cursor: pointer;
    z-index: 1;

    &:hover {
      svg {
        circle {
          fill: $color-pink;
        }
      }
    }

    svg {
      circle {
        fill: #0752E8;
        transition: $transition-default;
      }
    }
  }
}
</style>

<template>
  <section :class="prefix">
    <div class="container" ref="protection">
      <div class="row">
        <header :class="`${prefix}__header col-md-8 offset-md-2`" ref="header">
          <base-title :class="`${prefix}__title`" color="gradient">{{ $t(`${prefix}.title`) }}</base-title>
          <div :class="`${prefix}__description`">{{ $t(`${prefix}.description`) }}</div>
        </header>
      </div>
    </div>
    <div :class="`${prefix}__animation`">
      <img :class="`${prefix}__cube-bottom`" :src="imageCubeBottom" alt="CubeBottom" ref="cubeBottom">
      <div :class="`${prefix}__background`">
        <img :src="imageBackground" alt="Protection background">
        <div :class="`${prefix}__background_active`" ref="backgroundActive">
          <img :src="imageBackgroundActive" alt="Protection background active">
        </div>
      </div>
      <img :class="`${prefix}__cube`" :src="imageCube" alt="Cube" ref="cube">
      <div :class="`${prefix}__light`" ref="light"></div>
      <div :class="`${prefix}__desc`" ref="desc">
        <div :class="`${prefix}__desc-left`">
          <span ref="descLeft">{{ $t(`${prefix}.left`) }}</span>
          <img :src="imageDescLeft" alt="imageDescLeft">
        </div>
        <div :class="`${prefix}__desc-right`">
          <img :src="imageDescRight" alt="imageDescRight">
          <span ref="descRight">{{ $t(`${prefix}.right`) }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseTitle from '../components/Base/BaseTitle'
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

export default {
  name: 'Protection',
  data() {
    return {
      prefix: 'protection',
      imageBackground: require('../assets/images/protection-background.jpg'),
      imageBackgroundActive: require('../assets/images/protection-background-active.jpg'),
      imageCube: require('../assets/images/cube.png'),
      imageCubeBottom: require('../assets/images/cube-bottom.png'),
      imageDescLeft: require('../assets/images/cube-line-left.svg'),
      imageDescRight: require('../assets/images/cube-line-right.svg'),
    }
  },
  methods: {
    scrollAnimationStart() {
      const timeline = gsap.timeline()
      timeline.to(this.$refs.cube, {opacity: 1, translateY: 0, duration: 2}, 1)
      timeline.to(this.$refs.cubeBottom, {opacity: 1, translateY: 0, duration: 2}, 1)
      timeline.to(this.$refs.light, {width: '100vw', left: '0vw', opacity: 1, delay: 2, duration: 4}, 3)
      timeline.to(this.$refs.backgroundActive, {width: '100vw', left: '0vw', delay: 2, duration: 4}, 3)
      timeline.to(this.$refs.header, {opacity: 1, translateY: 0, delay: 3, duration: 2}, 7)
      timeline.to(this.$refs.light, {width: '10vw', left: '45vw', opacity: 0, delay: 3, duration: 2}, 7)
      timeline.to(this.$refs.desc, {opacity: 1, delay: 4, duration: 2}, 9)
      timeline.to(this.$refs.descLeft, {translateX: 0, opacity: 1, delay: 4, duration: 2}, 9)
      timeline.to(this.$refs.descRight, {translateX: 0, opacity: 1, delay: 4, duration: 2}, 9)

      ScrollTrigger.create({
        trigger: this.$refs.protection,
        animation: timeline,
        start: 'top+=25% center',
        end: 'top+=50% center',
        scrub: 3,
        pin: true,
      })
    },
  },
  mounted() {
    setTimeout(() => {
      this.scrollAnimationStart()
    }, 1000)
  },
  components: {BaseTitle},
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.protection {
  position: relative;
  background-color: black;
  overflow-x: hidden;

  &__header {
    position: relative;
    text-align: center;
    margin-bottom: $row-height * 10;
    opacity: 0;
    transform: translateY(-100px);
  }

  &__title {
    margin: $row-height * 4 0 $row-height;
  }

  &__description {
    font-size: 28px;
    line-height: 33px;
  }

  &__animation {
    position: relative;

    img {
      position: relative;
      max-width: 100%;
    }
  }

  &__background {
    position: relative;
    overflow: hidden;

    img {
      width: 100vw;
      max-width: 100vw;
      height: auto;
    }

    &_active {
      position: absolute;
      display: flex;
      justify-content: center;
      bottom: 0;
      width: 0;
      left: 50vw;
      overflow: hidden;
    }
  }

  &__light {
    position: absolute;
    opacity: 0;
    top: 3.5vw;
    width: 10vw;
    height: 10vw;
    left: 45vw;
    background: radial-gradient(50% 50% at 50% 50%, #339DFF 0%, rgba(51, 157, 255, 0) 100%);
    mix-blend-mode: normal;
  }

  &__cube {
    position: absolute !important;
    top: -15.6vw;
    left: 50%;
    margin-left: -7.5vw;
    width: 16vw;
    transform: translateY(-30vw);
    opacity: 0;
  }

  &__cube-bottom {
    position: absolute !important;
    top: -12.4vw;
    left: 50%;
    margin-left: -7.5vw;
    width: 16vw;
    transform: translateY(-30vw);
    opacity: 0;
  }

  &__desc {
    opacity: 0;

    &-left, &-right {
      position: absolute;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      color: #31DAD0;

      span {
        display: inline-block;
      }

      img {
        position: absolute;
      }
    }

    &-left {
      position: absolute;
      top: -5vw;
      right: 60vw;
      width: 19vw;

      img {
        right: -4vw;
        top: 0.7vw;
      }

      span {
        opacity: 0;
        transform: translateX(-100px);
      }
    }

    &-right {
      position: absolute;
      top: -6vw;
      left: 60vw;
      width: 19vw;

      span {
        opacity: 0;
        transform: translateX(100px);
      }

      img {
        left: -2vw;
        top: 0.7vw;
      }
    }
  }
}
</style>

<template>
  <div :class="prefix" id="erachain">
    <div :class="`${prefix}__background`" ref="backgroundWrapper">
      <img :src="backgroundImage" alt="First screen background">
      <div :class="`${prefix}__background-mona`" ref="mona">
        <img :src="monaImage" alt="Mona NFT">
      </div>
      <div :class="`${prefix}__background-neon`" ref="neon">
        <img :src="neonImage" alt="Neon NFT">
      </div>
      <div :class="`${prefix}__background-light`" ref="light">
        <img :src="lightImage" alt="Light NFT">
      </div>
      <div :class="`${prefix}__background-gradient`" ref="background"/>
    </div>
    <div :class="`${prefix}__header`">
      <img :class="`${prefix}__title`" ref="title" :src="erachainImage" alt="Erachain.NFT">
      <div :class="`${prefix}__description`" ref="desc">
        {{ $t(`${prefix}.desc-1`) }} <br>{{ $t(`${prefix}.desc-2`) }}
      </div>
    </div>
    <div class="container">
      <div :class="`${prefix}__content col-md-8 offset-md-2`" ref="content">
        <p>{{ $t(`${prefix}.content`) }}</p>
        <base-button
          :class="`${prefix}__button`"
          href="https://web.erachain.org/services/nft/create"
        >{{ $t(`${prefix}.create`) }}</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import {gsap} from '../myGsap'
import BaseButton from '../components/Base/BaseButton'

export default {
  name: 'FirstScreen',
  components: {BaseButton},
  data() {
    return {
      prefix: 'first-screen',
      backgroundImage: require('../assets/images/first-screen-background.jpg'),
      monaImage: require('../assets/images/mona.jpg'),
      neonImage: require('../assets/images/neon.png'),
      lightImage: require('../assets/images/light.png'),
      erachainImage: require('../assets/images/erachain-title.png'),
      background: null,
    }
  },
  methods: {
    startAnimationBackground() {
      const animate = gsap.timeline({onComplete: this.scrollAnimationBackground})
      animate.to(this.$refs.background, {
        backgroundImage: 'radial-gradient(45% 50% at 50% 40%, rgba(7, 3, 34, 0) 75%, #070322 100%)',
        delay: 1,
        duration: 1,
      })
      animate.to(this.$refs.mona, {opacity: 1}, 2)
      animate.to(this.$refs.light, {opacity: 1}, 2)
      animate.to(this.$refs.title, {opacity: 1, translateY: 0}, 2)
      animate.to(this.$refs.neon, {opacity: 1}, 2.7)
      animate.to(this.$refs.desc, {opacity: 1, translateY: 0}, 2.7)
    },
    scrollAnimationBackground() {
      gsap.to(this.$refs.background, {
        backgroundImage: 'radial-gradient(0% 0% at 50% 40%, rgba(7, 3, 34, 0) 75%, #070322 100%)',
        scrollTrigger: {
          trigger: this.$refs.background,
          start: 'top top',
          end: 'bottom+=200px top',
          scrub: true,
        },
      })
    },
    scrollAnimationContent() {
      gsap.to(this.$refs.content, {
        opacity: 1,
        translateY: 0,
        scrollTrigger: {
          trigger: this.$refs.title,
          start: 'top center',
          end: 'top+=400px center',
        },
      })
    },
  },
  async mounted() {
    this.startAnimationBackground()
    setTimeout(() => {
      this.scrollAnimationContent()
    }, 500)
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.first-screen {
  display: flex;
  flex-wrap: wrap;

  &__background {
    position: relative;
    max-width: 100%;
    padding: 0 12%;
    margin: 0 auto;
    overflow: hidden;

    &-gradient {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-image: radial-gradient(0% 0% at 50% 40%, rgba(7, 3, 34, 0) 75%, #070322 100%);
    }

    &-mona {
      position: absolute;
      top: 12.3%;
      left: 41.8%;
      width: 16.2%;
      opacity: 0;
    }

    &-neon {
      position: absolute;
      top: 14.7%;
      left: 42%;
      width: 15.8%;
      opacity: 0;
    }

    &-light {
      position: absolute;
      top: 0;
      left: 19.4%;
      width: 60.8%;
      mix-blend-mode: screen;
      opacity: 0;
    }

    &-neon, &-mona {
      img {
        width: 100%;
        height: auto;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__header {
    position: relative;
    width: 100%;
    margin-top: -5%;
    text-align: center;
  }

  &__title {
    width: 937px;
    max-width: 40%;
    height: auto;
    opacity: 0;
    transform: translateY(200px);
  }

  &__description {
    margin-top: $row-height * 1.5;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.14em;
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0;
    transform: translateY(200px);
  }

  &__content {
    margin-top: $row-height * 4;
    text-align: center;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    opacity: 0;
    transform: translateY(100px);
  }

  &__button {
    margin-top: $row-height * 1.5;
  }
}
</style>

<template>
  <div :class="prefix">
    <div :class="`${prefix}__wrapper`">
      <img :class="`${prefix}__image`" :src="imageClock" alt="imageClock">
      <img :class="`${prefix}__image ${prefix}__image-arrow`" :src="imageClockArrow" alt="ClockArrow" ref="clockArrow">
      <img :class="`${prefix}__image ${prefix}__image-arrow-center`" :src="imageClockArrowCenter"
           alt="ClockArrowCenter">
      <div :class="`${prefix}__eth`">
        <span :class="`${prefix}__count`">{{ $t(`${prefix}.eth-count`) }}</span>
        <span :class="`${prefix}__company`">
        <a href="https://digiconomist.net/ethereum-energy-consumption" target="_blank">Ethereum</a>
      </span>
      </div>
      <div :class="`${prefix}__btc`">
        <span :class="`${prefix}__count`">{{ $t(`${prefix}.btc-count`) }}</span>
        <span :class="`${prefix}__company`">
        <a href="https://digiconomist.net/bitcoin-energy-consumption" target="_blank">Bitcoin</a>
      </span>
      </div>
      <div :class="`${prefix}__era`">
        <span :class="`${prefix}__count`">{{ $t(`${prefix}.era-count`) }}</span>
        <span :class="`${prefix}__company`">Erachain</span>
      </div>
    </div>
    <div :class="`${prefix}__title`">{{ $t(`${prefix}.title`) }}</div>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue'
import {gsap} from '../myGsap'

export default {
  name: 'TheEcoClock',
  setup() {
    const clockArrow = ref({})
    onMounted(() => {
      if (clockArrow.value instanceof HTMLElement) {
        setTimeout(() => {
          gsap.to(clockArrow.value, {
            rotate: 0,
            scrollTrigger: {
              trigger: clockArrow.value,
              start: 'top+=100px center',
            },
            duration: 0.9,
            ease: 'none',
          })
        }, 1000)
      }
    })
    return {
      prefix: 'the-eco-clock',
      clockArrow,
      imageClock: require('../assets/images/clock.png'),
      imageClockArrow: require('../assets/images/clockArrow.png'),
      imageClockArrowCenter: require('../assets/images/clockArrowCenter.png'),
    }
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.the-eco-clock {
  position: relative;
  display: inline-block;

  &__wrapper {
    position: relative;
  }

  &__title {
    text-align: center;
    margin-top: $row-height * 2.5;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #31D0DA;
  }

  &__image {
    position: relative;

    &-arrow {
      position: absolute;
      top: 35%;
      left: 9%;
      transform: rotate(113deg);
      transform-origin: 62% 68px;
    }

    &-arrow-center {
      position: absolute;
      top: 44%;
      left: 40%;
    }
  }

  &__count {
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #00FFFF;
    white-space: nowrap;
  }

  &__company {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
  }

  &__eth {
    position: absolute;
    text-align: right;
    top: 0;
    right: 82%;
  }

  &__btc {
    position: absolute;
    bottom: -11%;
    left: 85%;
  }

  &__era {
    position: absolute;
    text-align: right;
    bottom: -13%;
    left: 0;
  }
}
</style>

<template>
  <section :class="prefix">
    <div class="container">
      <div class="row">
        <div :class="`${prefix}__video col-md-5`">
          <base-video :src="videoURL" ref="video"></base-video>
        </div>
        <div :class="`${prefix}__info col-md-6 offset-md-1`" ref="info">
          <header :class="`${prefix}__header`">
            <base-title>{{ $t(`${prefix}.title`) }}</base-title>
            <div :class="`${prefix}__description`">{{ $t(`${prefix}.description`) }}</div>
          </header>
          <div :class="`${prefix}__ps`">
            {{ $t(`${prefix}.ps`) }}
            <icon-emoji :class="`${prefix}__smile`" type="yay"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseTitle from '../components/Base/BaseTitle'
import IconEmoji from '../components/Icon/IconEmoji'
import BaseVideo from '../components/Base/BaseVideo'
import {useI18n} from 'vue-i18n'
import {computed, onMounted, ref} from 'vue'
import {gsap} from '../myGsap'

export default {
  name: 'Editor',
  components: {BaseVideo, IconEmoji, BaseTitle},
  setup() {
    const info = ref({})
    const video = ref({})
    const {locale} = useI18n()
    const videoURL = computed(() => {
      return locale.value === 'ru'
        ? require('../assets/video/editor-nft-ru.webm')
        : require('../assets/video/editor-nft-en.webm')
    })

    onMounted(() => {
      if (info.value instanceof HTMLElement) {
        setTimeout(() => {
          gsap.to(info.value, {
            opacity: 1,
            scrollTrigger: {
              trigger: info.value,
              start: 'top+=100px center',
            },
            duration: 0.9,
            ease: 'none',
            onComplete: () => video.value.play(),
          })
        }, 1000)
      }
    })

    return {
      prefix: 'editor',
      info,
      video,
      videoURL,
    }
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.editor {
  &__header {
    margin: $row-height * 2 0;
  }

  &__info {
    opacity: 0;
  }

  &__description {
    font-size: 28px;
    line-height: 33px;
    margin-top: $row-height;
  }

  &__ps {
    position: relative;
    color: $color-chetwode-blue;
  }

  &__smile {
    position: absolute;
    transform: scale(.3);
    transform-origin: left top;
    margin-left: 10px;
    margin-top: -5px;
  }
}
</style>

<template>
  <section :class="prefix" ref="step">
    <div class="row">
      <div :class="`${prefix}__video col-md-7`">
        <base-video-border>
          <base-video :src="video"/>
        </base-video-border>
      </div>
      <div :class="`${prefix}__info col-md-5`">
        <base-title :class="`${prefix}__title`" size="small">{{ $t(`${prefix}.title`) }}</base-title>
        <base-list>
          <base-list-item v-for="i in 5" :key="i">
            {{ $t(`${prefix}.li-${i}`) }}
          </base-list-item>
        </base-list>
        <base-blockquote :class="`${prefix}__ps`">
          {{ $t(`${prefix}.ps`) }}
          <icon-info @click="openModal"/>
        </base-blockquote>
      </div>
    </div>
    <teleport to="body">
      <the-token-info
        v-if="openedModal"
        @close="closeModal"
      />
    </teleport>
  </section>
</template>

<script>
import BaseVideoBorder from '../Base/BaseVideoBorder'
import BaseVideo from '../Base/BaseVideo'
import BaseTitle from '../Base/BaseTitle'
import {computed, onMounted, ref} from 'vue'
import {useI18n} from 'vue-i18n'
import BaseBlockquote from '../Base/BaseBlockquote'
import BaseList from '../Base/BaseList'
import BaseListItem from '../Base/BaseListItem'
import {gsap} from '../../myGsap'
import TheTokenInfo from '../TheTokenInfo'
import {useModal} from '../../use/modal'
import IconInfo from '../Icon/IconInfo'

export default {
  name: 'NftProcessStepThree',
  components: {IconInfo, TheTokenInfo, BaseListItem, BaseList, BaseBlockquote, BaseTitle, BaseVideo, BaseVideoBorder},
  setup() {
    const step = ref({})
    const {locale} = useI18n()
    const {openedModal, openModal, closeModal} = useModal()

    const video = computed(() => {
      return locale.value === 'ru'
        ? require('../../assets/video/create-nft-ru.webm')
        : require('../../assets/video/create-nft-en.webm')
    })

    onMounted(() => {
      if (step.value instanceof HTMLElement) {
        setTimeout(() => {
          gsap.to(step.value, {
            opacity: 1,
            scrollTrigger: {
              trigger: step.value,
              start: 'top+=100px center',
            },
            duration: 0.9,
            ease: 'none',
          })
        }, 1000)
      }
    })

    return {
      prefix: 'nft-process-step-three',
      video,
      step,
      openedModal,
      openModal,
      closeModal
    }
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.nft-process-step-three {
  font-size: 20px;
  line-height: 26px;
}
</style>

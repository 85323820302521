<template>
  <section :class="prefix" ref="step">
    <div class="row">
      <div :class="`${prefix}__header col-md-8 offset-md-2`">
        <base-title :class="`${prefix}__title`" size="small">{{ $t(`${prefix}.title`) }}</base-title>
        <div :class="`${prefix}__desc`">
          <span>{{ $t(`${prefix}.desc`) }}</span>
          <icon-info @click="openModal"/>
        </div>
      </div>
    </div>
    <div :class="`${prefix}__exchanger`">
      <img :src="exchangerImage" alt="Exchanger">
    </div>
    <teleport to="body">
      <the-token-info
        v-if="openedModal"
        @close="closeModal"
      />
    </teleport>
  </section>
</template>

<script>
import BaseTitle from '../Base/BaseTitle'
import {computed, onMounted, ref} from 'vue'
import {gsap} from '../../myGsap'
import IconInfo from '../Icon/IconInfo'
import TheTokenInfo from '../TheTokenInfo'
import {useModal} from '../../use/modal'
import {useI18n} from 'vue-i18n'

export default {
  name: 'NftProcessStepTwo',
  components: {TheTokenInfo, IconInfo, BaseTitle},
  setup() {
    const step = ref({})
    const {locale} = useI18n()
    const {openedModal, openModal, closeModal} = useModal()
    const exchangerImage = computed(() => {
      return locale.value === 'en'
        ? require('../../assets/images/exchanger_en.jpg')
        : require('../../assets/images/exchanger.jpg')
    })

    onMounted(() => {
      if (step.value instanceof HTMLElement) {
        setTimeout(() => {
          gsap.to(step.value, {
            opacity: 1,
            scrollTrigger: {
              trigger: step.value,
              start: 'top+=100px center',
            },
            duration: 0.9,
            ease: 'none',
          })
        }, 1000)
      }
    })
    return {
      prefix: 'nft-process-step-two',
      exchangerImage,
      step,
      openedModal,
      openModal,
      closeModal,
    }
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.nft-process-step-two {
  font-size: 20px;
  line-height: 26px;

  &__header {
    text-align: center;
  }

  &__desc {
    margin: $row-height * 1.5 0 $row-height;
  }

  &__exchanger {
    img {
      width: 112%;
      margin-left: -6%;
    }
  }
}
</style>

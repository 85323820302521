<template>
  <div :class="prefix">
    <div :class="`${prefix}__question`" @click="$emit('choiceOfQuestion', question)">
      <slot name="question"/>
    </div>
    <transition>
      <div :class="`${prefix}__answer`" v-if="$slots.answer">
        <slot name="answer"/>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'FaqItem',
  emits: ['choiceOfQuestion'],
  props: {
    question: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      prefix: 'faq-item',
    }
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.faq-item {
  &__question {
    font-size: 20px;
    line-height: 23px;
    font-weight: 500;
    padding-top: $row-height;
    padding-bottom: $row-height * 0.3;
    cursor: pointer;
  }

  &__answer {
    color: #C6C3D9;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.03em;
    margin: $row-height 0;
  }
}
</style>

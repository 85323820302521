<template>
  <component
    :is="type"
    :href="href"
    :class="['base-button', `base-button_color-${color}`, `base-button_size-${size}`]"
    :data-responsive="responsive"
    @click="onAction"
    target="_blank"
  >
    <slot/>
  </component>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'pink',
      validator(value) {
        return ['pink', 'blue', 'white'].includes(value)
      },
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium', 'big'].includes(value)
      },
    },
    responsive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['action'],
  name: 'BaseButton',
  computed: {
    type() {
      if (this.href) {
        return 'a'
      } else {
        return 'button'
      }
    },
  },
  methods: {
    onAction(event) {
      if (!this.href) {
        event.preventDefault()
        this.$emit('action')
      }
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.base-button {
  position: relative;
  display: inline-block;
  padding: 0 38px;
  border: none;
  transition: $transition-default;
  overflow: hidden;
  text-align: center;
  text-decoration: none;

  &[data-responsive="true"] {
    width: 100%;
  }

  & > * {
    position: relative;
  }

  &__pointer {
    $width: 30px;

    position: absolute;
    width: $width * 2;
    height: $width * 2;
    left: 50%;
    top: 50%;
    margin-left: -$width;
    margin-top: -$width;
    border-radius: $width;
    background-color: #ffb5d2;
    z-index: 0;
    opacity: 0;
    visibility: hidden;
    transition: $transition-default;
  }

  &_color {
    &-pink {
      background-color: $color-pink;
      color: white;

      &:hover {
        color: white;
        background-color: #d22571;
      }
    }

    &-blue {
      background-color: $color-blue;
      color: white;

      &:hover {
        color: white;
        background-color: #3074FB;
      }
    }

    &-white {
      background-color: white;
      color: $color-pink;

      &:hover {
        color: $color-pink;
        background-color: #fff4f4;
      }
    }
  }

  &_size {
    &-small {
      height: 42px;
      line-height: 42px;
      font-size: 18px;
      border-radius: 21px;
    }

    &-medium {
      height: 48px;
      line-height: 48px;
      font-size: 20px;
      border-radius: 24px;
    }
  }

  &:hover {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .4);

    .base-button__pointer {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>

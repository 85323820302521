<template>
  <section :class="prefix">
    <div class="container">
      <div class="row">
        <div :class="`${prefix}__data col-md-4`">
          <the-blockchain-data/>
        </div>
        <div :class="`${prefix}__info col-md-6 offset-md-2`">
          <header :class="`${prefix}__header`" ref="header">
            <base-title :class="`${prefix}__title`">{{ $t(`${prefix}.title`) }}</base-title>
            <div :class="`${prefix}__description`">{{ $t(`${prefix}.description`) }}</div>
          </header>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseTitle from '../components/Base/BaseTitle'
import TheBlockchainData from '../components/TheBlockchainData'
import {onMounted, ref} from 'vue'
import {gsap} from '../myGsap'

export default {
  name: 'Features',
  components: {TheBlockchainData, BaseTitle},
  setup() {
    const header = ref({})

    onMounted(() => {
      if (header.value instanceof HTMLElement) {
        setTimeout(() => {
          gsap.to(header.value, {
            opacity: 1,
            scrollTrigger: {
              trigger: header.value,
              start: 'top+=100px center',
            },
            duration: 0.9,
            ease: 'none',
          })
        }, 1000)
      }
    })

    return {
      prefix: 'features',
      header,
    }
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.features {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(7, 3, 34, 1) 100%);

  &__header {
    opacity: 0;
  }

  &__title {
    margin-bottom: $row-height;
  }

  &__description {
    font-size: 28px;
    line-height: 33px;
  }
}
</style>

<template>
  <section :class="prefix">
    <div class="container">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div :class="`${prefix}__wrapper`">
            <background-wave/>
            <div :class="`${prefix}__content`">
              <base-title :class="`${prefix}__title`">{{ $t(`${prefix}.title`) }}</base-title>
              <base-button
                color="white"
                href="https://web.erachain.org/services/nft/create"
              >{{ $t(`${prefix}.button`) }}
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BackgroundWave from '../components/Background/BackgroundWave'
import BaseTitle from '../components/Base/BaseTitle'
import BaseButton from '../components/Base/BaseButton'

export default {
  name: 'StartNft',
  components: {BaseButton, BaseTitle, BackgroundWave},
  setup() {
    return {
      prefix: 'start-nft',
    }
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.start-nft {
  &__wrapper {
    position: relative;
    border-radius: $row-height;
    overflow: hidden;
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: $row-height * 2.5 $row-height * 3.5;
    text-align: center;
  }

  &__title {
    margin-bottom: $row-height * 1.5;
  }
}
</style>

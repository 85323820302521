<template>
  <footer :class="prefix">
    <div :class="`container ${prefix}__wrapper`">
      <div :class="`${prefix}__copyright`">{{ $t(`${prefix}.copyright`, {year}) }}</div>
      <div :class="`${prefix}__social`">
        <base-link
          v-for="link in links"
          :href="link.href"
          :key="link.name"
          target="_blank"
          passive
        >{{ link.name }}
        </base-link>
      </div>
      <div :class="`${prefix}__contact`">
        <base-link :href="`mailto:${email}`" passive>{{ email }}</base-link>
      </div>
    </div>
  </footer>
</template>

<script>
import BaseLink from './Base/BaseLink'

export default {
  name: 'TheFooter',
  components: {BaseLink},
  setup() {
    const email = 'support@erachain.org'
    const links = [
      {name: 'facebook', href: 'https://www.facebook.com/pg/erachaineng/'},
      {name: 'telegram', href: 'https://t.me/erachain'},
      {name: 'instagram', href: 'https://www.instagram.com/erachainofficial/'},
      {name: 'vkontakte', href: 'https://vk.com/erachain'},
    ]
    return {
      prefix: 'the-footer',
      email,
      links,
      year: new Date().getFullYear(),
    }
  },
}
</script>

<style lang="scss">
@import "../assets/scss/variables";

.the-footer {
  padding: 38px 0;
  background: linear-gradient(269.7deg, #B91691 0%, #0752E8 100%);
  mix-blend-mode: normal;
  line-height: 20px;
  color: $color-grey;

  &__copyright {
    font-size: 14px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__social {
    display: flex;
    gap: 0 48px;
  }
}
</style>

<template>
  <h2 v-if="size === 'medium'" :class="`${prefix} ${prefix}_color-${color} ${prefix}_size-${size}`">
    <slot/>
  </h2>
  <h3 v-else-if="size === 'small'" :class="`${prefix} ${prefix}_color-${color} ${prefix}_size-${size}`">
    <slot/>
  </h3>
</template>

<script>
export default {
  name: 'BaseTitle',
  props: {
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium'].includes(value)
      },
    },
    color: {
      type: String,
      default: 'white',
      validator(value) {
        return ['white', 'gradient', 'pink'].includes(value)
      },
    },
  },
  setup() {
    return {
      prefix: 'base-title',
    }
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.base-title {
  margin: 0;
  padding: 0;
  font-weight: 600;

  &_size {
    &-small {
      font-size: 28px;
      line-height: 33px;
    }

    &-medium {
      font-size: 52px;
      line-height: 56px;
    }
  }

  &_color {
    &-white {
      color: white;
    }

    &-pink {
      color: $color-pink;
    }

    &-gradient {
      display: table;
      background: linear-gradient(88.32deg, #226CFF 9.35%, #F0026D 91.27%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
</style>
